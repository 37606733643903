import { invariant } from "@epic-web/invariant"
import { useRouteLoaderData } from "@remix-run/react"

import { useMemo } from "react"
import { type loader as rootLoader } from "#app/root.tsx"

export function useParentRouteData<T>(
	routeId: string,
	options?: { optional: false },
): NonNullable<ReturnType<typeof useRouteLoaderData<T>>>

export function useParentRouteData<T>(
	routeId: string,
	options: { optional: true },
): ReturnType<typeof useRouteLoaderData<T>> | undefined

export function useParentRouteData<T>(routeId: string, { optional = false } = {}) {
	const routeData = useRouteLoaderData<T>(routeId)
	if (!optional) {
		invariant(routeData, "Parent route data not found")
	}
	return routeData
}

export function useRootRouteData() {
	return useParentRouteData<typeof rootLoader>("root")
}

/**
 * @returns the request info from the root loader
 */
export function useRequestInfo() {
	const { requestInfo } = useRootRouteData()
	invariant(requestInfo, "No requestInfo found in root loader")
	return requestInfo
}

export function useDateFormatter() {
	const { locale } = useRootRouteData()
	return useMemo(
		() =>
			new Intl.DateTimeFormat(locale || "en-US", {
				year: "numeric",
				month: "short",
				day: "numeric",
			}),
		[locale],
	)
}
